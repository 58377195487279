var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "background" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("svgicon", { staticClass: "logo", attrs: { name: "logo" } }),
        _c("svgicon", {
          staticClass: "logo-text",
          attrs: { name: "logo-text" },
        }),
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "card-box form",
            class: { disabled: _vm.loading && _vm.loading },
            attrs: { "auto-complete": "on", model: _vm.form, rules: _vm.rules },
          },
          [
            !_vm.loginMethods
              ? _c("div", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: true,
                      expression: "true",
                    },
                  ],
                  staticClass: "spinner",
                  attrs: {
                    "element-loading-spinner": "el-icon-loading",
                    "element-loading-background": "rgba(0, 0, 0, 0)",
                  },
                })
              : _vm.loginMethods["google_auth"]
              ? _c(
                  "el-button",
                  {
                    attrs: { "element-loading-spinner": "el-icon-loading" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleGoogleLogin.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" Sign in with Google ")]
                )
              : [
                  _c("el-input", {
                    attrs: {
                      name: "email",
                      type: "text",
                      "auto-complete": "off",
                      placeholder: "Email",
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email",
                    },
                  }),
                  _c("el-input", {
                    attrs: {
                      name: "password",
                      type: "password",
                      "auto-complete": "off",
                      placeholder: "Password",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { "native-type": "submit" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handlePasswordLogin.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Sign in ")]
                  ),
                ],
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "span",
      { staticClass: "copyright" },
      [
        _vm._v(" Made with "),
        _c("heart", { attrs: { width: 12 } }),
        _vm._v(" by CodeFish Studio "),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }